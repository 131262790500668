import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  InputLabel,
  Select,
  AccordionDetails,
  TableHead,
  Accordion,
  AccordionSummary,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'

import React, {
  useState,
  Fragment,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../../../../assets/images/company_logo.jpg'
import CustomBreadcrumbs from '../../../../../common/CustomBreadcrumbs'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import CustomButton from '../../../../../common/CustomButton'
import { TASK_VIEW_PATH } from '../../../../../../helpers/constants/RoutePaths'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Avatar from 'react-avatar'
import SurveySuccess from './SurveySuccess'
import { uuid } from 'uuidv4'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import shortid from 'shortid'
import 'react-popper-tooltip/dist/styles.css'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SurveyInstruction from './innerComponents/SurveyInstruction'
import {
  GET_CATEGORY_LIST_URL,
  GET_CUSTOM_CATEGORY_URL,
  GET_CUSTOM_SUB_CATEGORY_URL,
  GET_PROJECT,
  GET_PROJECT_ITEM,
  GET_PROJECT_URL,
  GET_SAVED_PROJECT_USER_ITEM,
  GET_SCALE_RATING_BY_PROJECT,
  GET_SCALE_RATING_BY_SCALE_ID,
  GET_SUB_CATEGORY_LIST_URL,
  GET_TASK_PARTICIPANT_LIST,
  SAVED_OPEN_QUESTION_URL,
  SAVE_TASK_CONFIRMATION,
  UPDATE_PROJECT_USER_ITEM,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import TaskOpenEndedQuestions from './TaskOpenEndedQuestions'
import { getCategory } from '../../../../../../redux/action'
import { Pagination } from '@mui/material'
import {
  authHeader,
  getQueryParamsByName,
  getUserId,
} from '../../../../../common/CustomFunction'

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: '0px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  successBtn: {
    fill: theme.successColor,
  },
  descriptoion: {
    fontFamily: 'Roboto-Medium !important',
    fontSize: '16px !important',
    font: 'normal normal medium 16px/24px Roboto !important',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  pageheading: {
    color: '#000000DE !important',
    fontSize: '24px',
  },
  selected: {
    textAlign: ' left',
    color: ' #1565C0 !important',
    opacity: ' 1',
    fontFamily: 'Nunito-Regular !important',
  },
  unselected: {
    color: '#0000008A !important',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    minHeight: '0px !important',
  },
  expanded: {
    padding: '8px',
    margin: '0px !important',
  },
  paper: {
    width: '100%',
    padding: '10px',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'hidden',
    boxShadow: 'none',
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '20px',
    },
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',
    height: '100%',
  },

  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    height: '100%',
    lineHeight: '1.5',

    // or borderTop: '1px solid red'
  },
  tableCellk: {
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    height: '100%',
    lineHeight: '1.5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
    },
  },
  tableRow: {
    height: 50,
  },
  roottitle: {
    fontFamily: 'Nunito-SemiBold !important',
    fontSize: '17px !important',
  },
  subheading: {
    fontFamily: 'Roboto-Regular !important',
  },
  subheadingk: {
    fontFamily: 'Roboto-Regular !important',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      fontSize: '15px',
    },
  },
  showndisplay: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  participantd: {
    display: 'initial',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  subnamek: {
    marginTop: '1rem',
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0rem',
      marginLeft: '0rem',
    },
  },
  listcell: {
    justifyContent: 'center',
    display: 'flex',
    margin: 'auto',
    alignContent: 'center',

    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
      cursor: 'pointer',
    },
  },
  handlebg: {
    background: 'transparent !important',
    cursor: 'grab',
    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
      cursor: 'grab',
    },
  },
}))

export default function TaskDetails(props) {
  const classes = useStyles()
  const [isHover, setIsHover] = useState(false)
  const [participantsList, setParticipantsList] = useState([])
  const [savedItemList, setSavedItemList] = useState([])
  const [scaleRatingList, setScaleRatingList] = useState([])
  const [taskOEQuestions, setTaskOEQuestions] = useState([])
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [slectMultipledata, setslectMultipledata] = useState([])
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [state, setId] = useState({})
  const [completedItems, setCompletedItems] = useState([])
  const [confirmCheck, setConfirmCheck] = useState(false)
  const taskId = getQueryParamsByName('taskId')
  const history = useHistory()
  const [projectDetails, setprojectDetails] = useState({})
  let ThinkwiseCategory = useSelector((state) => state.ThinkwisecategoryList)
  let customCategory = useSelector((state) => state.CustomCategoryList)
  let CustomCategoryItems = useSelector((state) => state.CustomCategoryItems)
  let allCategory = [...ThinkwiseCategory, ...customCategory]
  //
  const Thinkwisesubcategory = useSelector(
    (state) => state.ThinkwisesubcategoryReducer
  )
  const CustomSubCategory = useSelector((state) => state.CustomSubCategoryList)
  let allSubCategory = [...Thinkwisesubcategory, ...CustomSubCategory]

  let { id = '' } = useParams()
  // const projectId = props.location.state.projectId
console.log('params id',id,props.match.params.id)
  async function getCategoryList() {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CATEGORY_LIST_URL,
    }
    const response = await API(config)
    if (response.status === 200) {
      return response.data.data
    }
  }

  async function getSubCategoryList() {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SUB_CATEGORY_LIST_URL,
    }
    const response = await API(config)
    if (response.status === 200) {
      return response.data.data
    }
  }

  async function getCustomCategoryList() {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_CATEGORY_URL,
      params: { UserId: getUserId() },
    }
    const response = await API(config)
    if (response.status === 200) {
      return response.data.data
    }
  }

  async function getCustomSubCategoryList() {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_SUB_CATEGORY_URL,
      params: { UserId: getUserId() },
    }
    const response = await API(config)
    if (response.status === 200) {
      return response.data.data
    }
  }

  const setCompetencyItems = (data, allCategory, allSubCategory) => {
    // console.log("data", data)
    let tempCategory = []
    let tempSubCategory = []
    data.forEach((item) => {
      const category = allCategory.find(
        (twItem) => twItem?.zCompetencyID == item?.zCompetencyID
      )
      if (category) {
        if (!tempCategory.includes(category)) {
          tempCategory.push(category)
        }
      }

      if (item?.zCompetencyID == 0) {
        const subCategory = allSubCategory.find(
          (twItem) => twItem.zCapabilityID == item.zCapabilityID
        )
        const category = allCategory.find(
          (twItem) => twItem?.zCompetencyID === subCategory?.zCompetencyID
        )
        if (category) {
          if (!tempCategory.includes(category)) {
            tempCategory.push(category)
          }
        }
      }

      //
      const subCategory = allSubCategory.find(
        (twItem) => twItem.zCapabilityID === item.zCapabilityID
      )
      if (subCategory) {
        if (!tempSubCategory.includes(subCategory)) {
          tempSubCategory.push(subCategory)
        }
      }
    })
    //console.log("datacategory", tempCategory)
    setCategory(tempCategory)
    setSubCategory(tempSubCategory)
  }
  const getCustomItemByCategory = async () => {
    const categoryItems = {}
    let getCatItemsConfig = {
      method: 'GET',
      headers: authHeader(),

      // url: GET_CUSTOM_ITEMS_BY_CATEGORY,
      params: {
        // LevelID: zLevelID,
      },
    }
    await Promise.all(
      customCategory.map(async (customCategory) => {
        getCatItemsConfig.params.CategoryId = customCategory.zCompetencyID
        try {
          const response = await API(getCatItemsConfig)
          if (response.status === 200) {
            const { data } = response.data
            //  categoryItems.push(...data);
            categoryItems[
              'CATEGORY_ITEMS_' + customCategory.zCompetencyID
            ] = data
          }
        } catch (err) {
          console.log(err)
        } finally {
          // setloading(false)
        }
      })
    )
  }
  useEffect(() => {
    Object.values(CustomCategoryItems).forEach((value) => {})
    // setItems()

    // setSubCategory()
  }, [CustomCategoryItems])
  async function getSelectedProjectItems(projectId) {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT_ITEM,
      params: {
        ProjectID: props.match.params.id,
      },
    }
    const response = await API(getConfig)
    if (response.status === 200) {
      const { data } = response.data
      setItems(data)

      if (allCategory.length && allSubCategory.length) {
        setCompetencyItems(data, allCategory, allSubCategory)
      } else {
        console.log('else')
        const category = await getCategoryList()
        const subCategory = await getSubCategoryList()
        const customSubCategory = await getCustomCategoryList()
        const customCategory = await getCustomSubCategoryList()
        let allCategory = [...category, ...customCategory]
        let allSubCategory = [...subCategory, ...customSubCategory]
        setCompetencyItems(data, allCategory, allSubCategory)
      }
    }
  }

  async function getSavedProjectItems() {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SAVED_PROJECT_USER_ITEM,
      params: {
        ProjectID: props.match.params.id,
        RaterID: getUserId(),
      },
    }
    const response = await API(getConfig)
    if (response.status === 200) {
      const { ProjectUserItemList } = response.data
      setSavedItemList(ProjectUserItemList)
    }
  }

  async function getScaleRating(projectId) {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SCALE_RATING_BY_PROJECT,
      params: {
        ProjectID: props.match.params.id,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setScaleRatingList(data.data)
        // const [state, setId] = useState({ [uuid()]: [], [uuid()]: [] });
        let newObject = data.data.map((el) => {
          let obj = {}
          obj[`${el.zScaleRatingID}`] = []
          // return (Object[el.zScaleRatingID]=[])
          return obj
        })
        const allObject = Object.assign({}, ...newObject)
        // setId(allObject)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getTaskQuestionList() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: SAVED_OPEN_QUESTION_URL,
      params: {
        ProjectID: props.match.params.id,
        // RespondentID: '' ,
        // ParticipantId: '',
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setTaskOEQuestions(data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getParticipantList() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TASK_PARTICIPANT_LIST,
      params: {
        ProjectID: props.match.params.id,
        RespondentID: getUserId(),
        // ParticipantId: '',
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (data) {
        let participantList = data.participantlist
        participantList.map((participant) => (participant.id = uuid()))
        setParticipantsList(participantList)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getSavedProjectItems()
    getTaskQuestionList()
    getParticipantList()
    getScaleRating()
    getSelectedProjectItems()
  }, [])

  useEffect(() => {
    if (participantsList && participantsList.length === 1) {
      getParticipate({ index: 0, draggableId: participantsList[0].id })
    }
  }, [participantsList])

  useMemo(() => {
    const temp = { ...state }
    items.forEach((item) => {
      scaleRatingList.forEach((scaleRating) => {
        if (!temp[`${item.zItemID}_${scaleRating.zScaleRatingID}`]) {
          temp[`${item.zItemID}_${scaleRating.zScaleRatingID}`] = []
        }
      })
    })
    setId(temp)
  }, [scaleRatingList, items])

  useEffect(() => {
    const temp = { ...state }

    items.forEach((item) => {
      scaleRatingList.forEach((scaleRating, index) => {
        const savedItem = savedItemList.filter(
          (sItem) =>
            sItem.zItemID === item.zItemID &&
            sItem.zScaleRatingID === scaleRating.zScaleRatingID
        )
        if (savedItem.length) {
          savedItem.map((item) => {
            let participant = participantsList.find(
              (participant) => participant.zParticipantID === item.zUserID
            )
            temp[`${item.zItemID}_${scaleRating.zScaleRatingID}`].push({
              ...participant,
              id: uuid(),
            })
          })
        }
      })
    })
    setId(temp)
  }, [savedItemList, items, participantsList])

  let idneed = ''
  const hoversetFun = (e) => {
    idneed = e.currentTarget.dataset.tid
    setIsHover(true)
  }
  const removeRate = (e) => {
    const content = e.currentTarget.dataset.dragcontent
    const id = e.currentTarget.dataset.dragid
    let temp = state
    Object.keys(temp).map((list, i) => {
      return temp[list].length
        ? temp[list].map((rec, index) => {
            return rec.zParticipantFullName == content
              ? temp[list].splice(rec.content, 1)
              : ''
          })
        : ''
    })
    setId({ ...temp })
  }
  const [isOpenSurveyTour, setSurveyTour] = useState(false)

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    console.log('reorder')
    return result
  }

  const copy = (source, destination, droppableSource, droppableDestination) => {
    const currentQuestion = droppableDestination.droppableId.split('_')[0]
    const sourceClone = Array.from(source)
    const item = sourceClone[droppableSource.index]
    const found = state[droppableDestination.droppableId]
    let lookup = found.findIndex(
      (x) => x.zParticipantFullName == item.zParticipantFullName
    )
    console.log('copied')

    let destClone = Array.from(destination)
    Object.keys(state).map((list, i) => {
      if (list.split('_')[0] == currentQuestion) {
        return state[list].length
          ? state[list].map((rec, index) => {
              return rec.zParticipantFullName == item.zParticipantFullName
                ? (lookup = 1)
                : ''
            })
          : ''
      }
    })

    if (lookup == -1) {
      handleSaveItem(
        item.zParticipantID,
        currentQuestion,
        droppableDestination.droppableId.split('_')[1]
      )
      destClone.splice(droppableDestination.index, 0, {
        ...item,
        id: uuid(),
      })
    }

    return destClone
  }
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)
    const result = state
    if (
      droppableSource.droppableId.split('_')[0] ===
      droppableDestination.droppableId.split('_')[0]
    ) {
      const temp = droppableDestination.droppableId.split('_')
      handleSaveItem(removed.zParticipantID, temp[0], temp[1])
      result[droppableSource.droppableId] = sourceClone
      result[droppableDestination.droppableId] = destClone
    }

    return result
  }
  const Content = styled.div`
    margin-right: 2px;
  `
  const Itemlisting = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: #fff;
    ${(props) => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};
  `
  const Item = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: transparent;
    ${(props) => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};
  `
  // width: 100%;
  // display: flex;
  const Clone = styled(Item)`
    + div {
      display: none !important;
    }
  `

  const Handle = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    user-select: none;
    line-height: 1.5;
    border-radius: 3px 0 0 3px;
    background: #fff;
    color: #000;
  `
  // border: 1px

  const List = styled.div`
    ${(props) => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
    background: #fff;
    padding: 0.5rem 0.5rem 0;
    flex: 0 0 150px;
    font-family: sans-serif;
  `
  const ListAvatar = styled.div`
    ${(props) => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
    background: transparent;
    padding: 0.5rem 0.5rem 0;
    flex-wrap: wrap;
    font-family: sans-serif;
    width: 100%;
    width: 100px;
    display: flex;
  `
  const Kiosk = styled(List)`
    position: absolute;
    width: 200px;
  `

  const Container = styled(List)`
    background: #ccc;
  `

  const Notice = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0 0.5rem 0.5rem;
    border: 1px solid transparent;
    line-height: 1.5;
    color: #aaa;
  `

  const Button = styled.button`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 0.5rem;
    padding: 0.5rem;
    color: #000;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 3px;
    font-size: 1rem;
    cursor: pointer;
  `

  const ButtonText = styled.div`
    margin: 0 1rem;
  `

  const onDragEnd = (result) => {
    const { source, destination } = result
    console.log(result)
    // dropped outside the list
    if (!destination) {
      return
    }

    switch (source.droppableId) {
      case destination.droppableId:
        setId({
          ...state,
          [destination.droppableId]: reorder(
            state[source.droppableId],
            source.index,
            destination.index
          ),
        })
        break
      case 'participantList':
        setId({
          ...state,
          [destination.droppableId]: copy(
            participantsList,
            state[destination.droppableId],
            source,
            destination
          ),
        })

        break
      default:
        setId(
          move(
            state[source.droppableId],
            state[destination.droppableId],
            source,
            destination
          )
        )
        break
    }
  }

  const userList = [
    { id: '1', content: 'Steve Griffen' },
    { id: '2', content: "Heath O'Leary" },
    { id: '3', content: 'Jennifer Jones' },
    { id: uuid(), content: 'Alexa Google' },
    { id: uuid(), content: 'Will Robinson' },
    { id: uuid(), content: 'Matt Smith' },
    { id: uuid(), content: 'Erica Busch' },
  ]
  const [submitFlag, setFlag] = useState(false)
  const submitFlagFun = () => {
    setFlag(!submitFlag)
  }

  const handleSurveyInstruction = () => {
    setSurveyTour(!isOpenSurveyTour)
  }

  const handleSubmitTask = async () => {
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_TASK_CONFIRMATION,
      data: {
        taskUserID: taskId,
        projectID: props.match.params.id,
        userID: getUserId(),
        sessionID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      },
    }
    const response = await API(config)

    if (response.status === 200) {
      history.push('/tasks')
    }
  }

  const handleSaveItem = async (participantId, itemId, scaleRatingId) => {
    const updateConfig = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_PROJECT_USER_ITEM,
      data: {
        projectID: props.match.params.id,
        userID: participantId,
        raterID: getUserId(),
        itemID: Number(itemId),
        scaleRatingID: Number(scaleRatingId),
      },
    }
    await API(updateConfig)
    // getSavedProjectItems()
  }

  useEffect(() => {
    const currentState = state
    let tempCompletedItems = []

    items.forEach((item) => {
      let items = 0
      scaleRatingList.forEach((scale) => {
        Object.keys(currentState).map((value) => {
          if (`${item.zItemID}_${scale.zScaleRatingID}` === value) {
            items += currentState[value].length
          }
        })
        if (items === participantsList.length) {
          if (!tempCompletedItems.includes(item.zItemID)) {
            tempCompletedItems.push(item.zItemID)
            items = 0
          }
        }
        //  else {
        //   if(tempCompletedItems.includes(item.zItemID)) {
        //     tempCompletedItems = tempCompletedItems.filter(id=> id !== item.zItemID)
        //   }
        //  }
      })
    })
    setCompletedItems(tempCompletedItems)
  }, [state])

  const clickFordrag = (id, index) => {
    let DragedslectMultipledata = state[id]
    let movedData = slectMultipledata?.[DragedslectMultipledata.length]
    if (movedData)
      setId({
        ...state,
        [id]: copy(
          participantsList,
          state[id],
          { index: movedData.index },
          { droppableId: id }
        ),
      })
  }

  const getParticipate = (value) => {
    const { index, draggableId } = value
    //     let alreadySelectedvalue = slectMultipledata
    //       .map((val) => val.draggableId)
    //       .includes(draggableId)
    // console.log("already selected", alreadySelectedvalue)
    //     if (alreadySelectedvalue) {
    //       let newSelectedvalue = slectMultipledata.filter(
    //         (val) => val.draggableId != draggableId
    //       )
    //       setslectMultipledata(newSelectedvalue)
    //       console.log("if newSelected", newSelectedvalue)
    //     } else {
    //       console.log("else ", value)
    //      // setslectMultipledata((val) => [...val, value])
    //      setslectMultipledata((val) => [value])
    //     }
    setslectMultipledata((val) => [value])
  }

  // const getParticipateofSingle = (value) => {
  //   const { index, draggableId } = value
  //   setslectMultipledata((val) => [value])
  // }

  const getTask = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT,
      params: {
        ProjectID: id,
      },
    }
    try {
      const response = await API(config)
      const { data } = response.data
      setprojectDetails(data[0])
    } catch (er) {
      console.error(er)
    }
  }

  const SurveyTable = useCallback((value) => {
    const { zName, zDescription } = value
    return (
      <>
        {' '}
        <p style={{ margin: '0px 0px' }}>{zName}</p>
        <p style={{ margin: '0px 0px' }}>{zDescription || ''}</p>
      </>
    )
  }, [])

  useEffect(() => {
    getTask()
  }, [])
  let contenttask = useRef()
  let overAllref = useRef()
  let openend = useRef()

  useEffect(() => {
    // contenttask?.current?.scrollIntoView({
    //   behavior: 'smooth',
    // })
    // overAllref?.current?.scrollIntoView({
    //   behavior: 'smooth',
    // })
    // openend?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [currentPage])

  //console.log("category length",category.length, "currentpage",currentPage , (currentPage === (category.length + 1)) ,(completedItems.length === items.length) )
  return (
    <div>
      <Grid container spacing={1}>
        {/* <Grid item xs={6} lg={6} md={6}>
          <Typography className={classes.pageheading} variant="h6" gutterBottom>
            Tasks - ThinkWise 360!
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes.selected} color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.selected} color="textPrimary">
              Tasks
            </Typography>
            <Typography className={classes.unselected} color="textPrimary">
              Project Title
            </Typography>
          </Breadcrumbs>
        </Grid> */}
        {/* <Grid item xs={6} lg={6} md={6} style={{ textAlign: 'right' }}> */}
        {/* <div className={classes.sideHead}>
            <img src={logo} alt="logo" width="100" height="60" />
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12}>
          <Divider />
        </Grid> */}
        <Grid item xs={10}>
          <Typography variant="h6" gutterBottom>
            Project Title
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Project Description</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.sideHead}>
            <InputLabel>Google Translation</InputLabel>
            <div
              id="google_translate_element2"
              style={{ margin: '10px 0px' }}></div>
            {/* <Select labelId="scale-label" label="Translation" fullWidth>
              <MenuItem value="select">Select a language</MenuItem>
            </Select> */}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        <div
          ref={(e) => {
            overAllref.current = e
          }}>
          <Paper className={classes.paper}>
            {/* Table tools */}
            <Toolbar className={classes.root}>
              <Typography
                className={classes.roottitle}
                variant="h6"
                id="tableTitle"
                component="div">
                Please rate each Participant for all of the items listed below.
              </Typography>
              <div>
                <p
                  style={{ cursor: 'pointer', textAlign: 'right' }}
                  onClick={() => handleSurveyInstruction()}>
                  Survey Instructions
                </p>
              </div>
            </Toolbar>
            <Divider />
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
                <Grid
                  item
                  lg={3}
                  md={3}
                  className={classes.showndisplay}></Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    className={classes.subheading}
                    variant="h6"
                    gutterBottom>
                    Items
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} xs={6} style={{ textAlign: 'right' }}>
                  <Typography color="textPrimary">
                    Completed Items {completedItems.length} of {items.length}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  xs={0}
                  style={{ padding: '0px 12px' }}></Grid>
                <Grid
                  item
                  lg={9}
                  md={9}
                  xs={12}
                  style={{ padding: '0px 12px' }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      className={classes.subheading}
                      color="textPrimary">
                      Rate each participant for each item. An item is complete
                      when all participants are rated and the green check mark
                      appears
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  lg={3}
                  className={classes.participantd}>
                  <Grid item xs={3} sm={3} md={12} lg={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.subheadingk}>
                      Participants
                    </Typography>
                  </Grid>

                  <Grid item xs={3} sm={3} md={12} lg={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Typography
                        color="textPrimary"
                        className={classes.subheadingk}>
                        Select a participant from the list
                      </Typography>
                    </Breadcrumbs>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={12}
                    lg={12}
                    disabled={true}
                    className={classes.subnamek}
                    style={{}}>
                    <div>
                      <Droppable
                        droppableId="participantList"
                        isDropDisabled={true}>
                        {(provided, snapshot) => (
                          <Kiosk
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}>
                            {participantsList.map((item, index) => (
                              <div
                                onClick={() =>
                                  getParticipate({
                                    index: index,
                                    draggableId: String(item.id),
                                  })
                                }
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: slectMultipledata.find(
                                    (val) => val.draggableId == String(item.id)
                                  )
                                    ? '#ECEDEF'
                                    : 'none',
                                }}>
                                <Draggable
                                  key={String(item.id)}
                                  draggableId={String(item.id)}
                                  index={index}>
                                  {(provided, snapshot) => (
                                    <Fragment>
                                      {/* <FormControlLabel
                                      control={
                                        <Checkbox
                                          onClick={() =>
                                            getParticipate({
                                              index: index,
                                              draggableId: String(item.id),
                                            })
                                          }
                                          checked={slectMultipledata.find(
                                            (val) =>
                                              val.draggableId == String(item.id)
                                          )}
                                          color="primary"
                                        />
                                      }
                                    /> */}

                                      <Item
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        isDragging={snapshot.isDragging}
                                        style={provided.draggableProps.style}>
                                        {/* {item.content} */}
                                        <Avatar
                                          name={item.zParticipantFullName}
                                          size="30"
                                          round={true}
                                        />
                                        {/* {item.content} */}
                                      </Item>
                                      {snapshot.isDragging && (
                                        // <Clone>{item.content}</Clone>
                                        <Item>
                                          <Avatar
                                            name={item.zParticipantFullName}
                                            size="30"
                                            round={true}
                                          />
                                        </Item>
                                      )}
                                      <div {...provided.dragHandleProps}>
                                        {item.zParticipantFullName}
                                      </div>
                                    </Fragment>
                                  )}
                                </Draggable>
                              </div>
                            ))}
                          </Kiosk>
                        )}
                      </Droppable>
                    </div>
                    {/* <List dense className={classes.root}>
                    {userList.map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value}`;
                      return (
                        <ListItem key={value} button>
                          <ListItemAvatar>
                            <Avatar name={value} size="30" round={true} />
                          </ListItemAvatar>
                          <ListItemText id={labelId} primary={value} />
                        </ListItem>
                      );
                    })}
                  </List> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  md={9}
                  style={{ overflow: 'auto', height: '498px', width: '100%' }}>
                  {category.length && category[currentPage - 1] ? (
                    <Accordion
                      ref={(e) => {
                        contenttask.current = e
                      }}
                      expanded={true}
                      className={classes.acc}>
                      <AccordionSummary
                        aria-controls={`item_-content`}
                        id={`item_-header`}
                        classes={{
                          expanded: classes.expanded,
                        }}
                        className={classes.accHead}>
                        <Grid item xs={10}>
                          <Typography className={classes.heading}>
                            {category[currentPage - 1].zName}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accDetail}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          justifyContent="center">
                          <Grid item xs={12}>
                            <p style={{ margin: '0px 0px' }}>
                              {category[currentPage - 1].zDescription}
                            </p>
                          </Grid>
                          {subCategory.map((sub) => {
                            if (
                              sub?.zCompetencyID ===
                              category[currentPage - 1]?.zCompetencyID
                            ) {
                              return (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      backgroundColor: '#F6F6F6',
                                      margin: '0px 0px',
                                    }}>
                                    <b>
                                      {' '}
                                      <p
                                        style={{
                                          fontFamily:
                                            'Roboto-Regular !important',
                                          fontSize: '16px',
                                          margin: '0px 0px',
                                        }}>
                                        {sub.zName}
                                      </p>
                                    </b>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <p
                                      style={{
                                        fontFamily: 'Roboto-Regular !important',
                                        fontSize: '14px',
                                        margin: '0px 0px',
                                      }}>
                                      {sub.zDescription}
                                    </p>
                                  </Grid>

                                  <Grid item xs={12}>
                                    {items.map((item, index) => {
                                      if (
                                        item.zCapabilityID === sub.zCapabilityID
                                      )
                                        return (
                                          <>
                                            {' '}
                                            <p
                                              className={classes.descriptoion}
                                              style={{
                                                display: 'flex',
                                                fontWeight: 700,
                                                margin: '0px 0px',
                                              }}>
                                              <CheckCircleOutlineIcon
                                                className={classes.successBtn}
                                                style={{
                                                  visibility: `${
                                                    completedItems.includes(
                                                      item.zItemID
                                                    )
                                                      ? 'visible'
                                                      : 'hidden'
                                                  }`,
                                                }}
                                              />
                                              {index + 1}. {item.zCaption}
                                            </p>
                                            <TableContainer
                                              component={Paper}
                                              className={classes.tablecontainer}
                                              style={{
                                                borderRadius: 'unset',
                                                overflowX: 'auto',
                                              }}>
                                              <Table
                                                stickyHeader
                                                size="large"
                                                className={classes.customTable}
                                                style={{
                                                  borderColor: '#C3C3C3',
                                                  borderCollapse: 'collapse',
                                                  marginBottom: '1rem',
                                                }}>
                                                {/* Table Header */}
                                                {/* <CustomTableHeader
                              {...tableHeadProps}
                              className={classes.tableCell}
                            /> */}
                                                <TableHead>
                                                  <TableRow>
                                                    {scaleRatingList.map(
                                                      (scaleRating) => (
                                                        <TableCell
                                                          className={
                                                            classes.tableCell
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              '#D0E9FC',
                                                            borderColor:
                                                              '#C3C3C3',
                                                            padding:
                                                              '6px 6px 6px 6px',
                                                            height: '1rem',
                                                            textAlign: 'center',
                                                            width: '300px',
                                                          }}>
                                                          <Grid>
                                                            {SurveyTable(
                                                              scaleRating
                                                            )}
                                                          </Grid>
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                {/* Table Header */}

                                                {/* Table Body */}
                                                <TableBody>
                                                  <TableRow
                                                    className={
                                                      classes.tableRow
                                                    }>
                                                    {scaleRatingList.map(
                                                      (scale) => (
                                                        <TableCell
                                                          align="left"
                                                          className={
                                                            classes.tableCellk
                                                          }
                                                          style={{
                                                            borderColor:
                                                              '#C3C3C3',
                                                            height: '100px',
                                                          }}>
                                                          <Content id="table-content">
                                                            {Object.keys(
                                                              state
                                                            ).map((list, i) => {
                                                              // console.log(
                                                              //   list,
                                                              //   `${item.zItemID}_${scale.zScaleRatingID}`,
                                                              //   'item'
                                                              // )
                                                              return list ===
                                                                `${item.zItemID}_${scale.zScaleRatingID}` ? (
                                                                <Droppable
                                                                  key={list}
                                                                  droppableId={
                                                                    list
                                                                  }>
                                                                  {(
                                                                    provided,
                                                                    snapshot
                                                                  ) => (
                                                                    <ListAvatar
                                                                      className={
                                                                        classes.listcell
                                                                      }
                                                                      style={{
                                                                        height:
                                                                          '10px',
                                                                        width:
                                                                          '125px',
                                                                      }}
                                                                      onClick={() =>
                                                                        clickFordrag(
                                                                          list,
                                                                          i
                                                                        )
                                                                      }
                                                                      ref={
                                                                        provided.innerRef
                                                                      }
                                                                      isDraggingOver={
                                                                        snapshot.isDraggingOver
                                                                      }>
                                                                      {state[
                                                                        list
                                                                      ].length
                                                                        ? state[
                                                                            list
                                                                          ].map(
                                                                            (
                                                                              item,
                                                                              index
                                                                            ) => {
                                                                              return (
                                                                                <Draggable
                                                                                  key={
                                                                                    item.id
                                                                                  }
                                                                                  draggableId={
                                                                                    item.id
                                                                                  }
                                                                                  index={
                                                                                    index
                                                                                  }>
                                                                                  {(
                                                                                    provided,
                                                                                    snapshot
                                                                                  ) => (
                                                                                    <Item
                                                                                      ref={
                                                                                        provided.innerRef
                                                                                      }
                                                                                      {...provided.draggableProps}
                                                                                      isDragging={
                                                                                        snapshot.isDragging
                                                                                      }
                                                                                      style={{
                                                                                        ...provided
                                                                                          .draggableProps
                                                                                          .style,
                                                                                      }}
                                                                                      id={`tooltip_${item.id}`}>
                                                                                      <Handle
                                                                                        className={
                                                                                          classes.handlebg
                                                                                        }
                                                                                        {...provided.dragHandleProps}>
                                                                                        {/* {item.content} */}
                                                                                        <Avatar
                                                                                          name={
                                                                                            item.zParticipantFullName
                                                                                          }
                                                                                          size="30"
                                                                                          round={
                                                                                            true
                                                                                          }
                                                                                        />
                                                                                      </Handle>
                                                                                    </Item>
                                                                                    // </Tooltip>
                                                                                  )}
                                                                                </Draggable>
                                                                              )
                                                                            }
                                                                          )
                                                                        : ''}
                                                                      {
                                                                        provided.placeholder
                                                                      }
                                                                    </ListAvatar>
                                                                  )}
                                                                </Droppable>
                                                              ) : (
                                                                ''
                                                              )
                                                            })}
                                                          </Content>
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </>
                                        )
                                    })}
                                  </Grid>
                                </>
                              )
                            }
                          })}

                          <Grid item xs={1}></Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ''
                  )}

                  {category.length + 1 === currentPage ? (
                    <div style={{ paddingTop: '40px' }}>
                      <TaskOpenEndedQuestions
                        ref={openend}
                        participantList={participantsList}
                        taskOEQuestions={taskOEQuestions}
                        projectId={props.match.params.id}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Grid>
                {submitFlag && (
                  <SurveySuccess
                    open={submitFlag}
                    handleReset={submitFlagFun}
                  />
                )}
                {isOpenSurveyTour && (
                  <SurveyInstruction
                    open={isOpenSurveyTour}
                    handleReset={handleSurveyInstruction}
                  />
                )}
              </Grid>
            </DragDropContext>
            <Grid container>
              <Grid lg={3} md={3} className={classes.showndisplay}></Grid>
              <Grid xs={12} lg={9} md={9}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '20px',
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Pagination
                      page={currentPage}
                      count={category.length + 1}
                      color="primary"
                      onChange={(e, page) => {
                        // contenttask?.current?.scrollIntoView({
                        //   behavior: 'smooth',
                        // })
                        // openend?.current?.scrollIntoView({ behavior: 'smooth' })
                        // rell?.current?.scrollIntoView({ behavior: 'smooth' })
                        setCurrentPage(page)
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid xs={3}></Grid>
              <Grid xs={9}>
                {completedItems.length === items.length &&
                  currentPage === category.length + 1 && (
                    <div>
                      <p>
                        By checking the box below and clicking SUBMIT, I
                        understand my ratings are final and cannot be changed.
                      </p>
                      <p>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name="all"
                              // checked={selectAll}
                              onClick={(e) =>
                                e.target.checked
                                  ? setConfirmCheck(true)
                                  : setConfirmCheck(false)
                              }
                            />
                          }
                          label="Yes, all ratings are final."
                        />
                      </p>
                      <CustomButton
                        colour="primaryButton"
                        disabled={!confirmCheck}
                        onClick={handleSubmitTask}>
                        Submit
                      </CustomButton>
                    </div>
                  )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Grid>
    </div>
  )
}
