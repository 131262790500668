import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import React, { useEffect, useState } from 'react'
import CustomTableHeader from '../common/CustomTableHeader'
import {
  CustomPageLoading,
  getComparator,
  stableSort,
} from '../../function/HelperFunction'
import moment from 'moment'
import CustomButton from '../common/CustomButton'
import { authHeader, getUserId } from '../common/CustomFunction'
import { GET_USER_SEND_REPORT } from '../../helpers/constants/ApiRoutes'
import { API } from '../../helpers/API'
import { Link, useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.btnPrimary,
    '& .MuiButton-label': {
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
      width: '0% ',
    },
  },
  inprogressBtn: {
    padding: '8px 22px',
    backgroundColor: theme.btnPrimary,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
  },
  tablecontainer: {
    marginTop: '1rem',
    // maxHeight: 440,
    minHeight: '50vh',
    //overflowX: "hidden",
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 2px 6px 10px',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  circularloader: {
    display: 'flex',
    justifyContent: 'center',
    width: '300%',
    paddingTop: '20%',
  },
  heading: {
    // fontWeight: 'bold',
    color: '#000000DE !important',
    fontSize: '24px',
  },
  subheading: {
    fontFamily: 'Nunito-Regular !important',
  },
  inactive: {
    fontFamily: 'Nunito-Regular !important',
    color: '#1565C0 !important',
  },
}))
export default function UserReportList() {
  const classes = useStyles()
  const tableColumns = [
    { id: 'zReportName', title: 'Report Name', sort: true },
    { id: 'zReportType', title: 'Type', sort: true },
    { id: 'zReportDate', title: 'Date', sort: true },
    { id: 'zReportLink', title: 'View', sort: true },
  ]
  const tableHeadProps = { tableColumns }
  const [projectReportList, setProjectList] = useState([])
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('zProjectName')

  const handleRequestSort = (event, property) => {
    console.log(property)
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const getReportList = async () => {
    setLoading(true)
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: GET_USER_SEND_REPORT,
      params: {
        UserID: parseInt(getUserId()),
      },
    }
    try {
      const response = await API(config)
      console.log('response', response.data.data)
      setProjectList(response.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    console.log('log',getUserId())
    getReportList()
  }, [])
  return (
    <Grid item sm={12} lg={12} md={12}>
      <Grid container spacing={1} item sm={12} lg={12} md={12}></Grid>

      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        <div>
          <Paper className={classes.paper}>
            {/* {loading && <CustomPageLoading />} */}

            {/* Table tools */}
            <Toolbar className={classes.root}>
              <Typography
                className={classes.tblHead}
                variant="h6"
                id="tableTitle"
                component="div">
                Reports
              </Typography>
            </Toolbar>
            <Divider />
            <TableContainer
              component={Paper}
              className={classes.tablecontainer}>
              <Table stickyHeader size="small" className={classes.customTable}>
                {/* Table Header */}
                <CustomTableHeader
                  {...tableHeadProps}
                  // order={order}
                  // orderBy={orderBy}
                  // onRequestSort={handleRequestSort}
                />
                {/* Table Header */}

                {/* Table Body */}
                {loading && (
                  <TableBody>
                    <div className={classes.circularloader}>
                      <CircularProgress size={28} />
                    </div>
                  </TableBody>
                )}
                {!loading && (
                  <TableBody>
                    {projectReportList.length > 0 &&
                      stableSort(
                        projectReportList,
                        getComparator(order, orderBy)
                      ).map((record, index) => {
                        let link = record.zReportLink
                        return (
                          <>
                            <TableRow key={record.zReportID}>
                              <TableCell className={classes.subheading}>
                                {record.zReportName}
                              </TableCell>
                              <TableCell className={classes.subheading}>
                                {record.zReportType}
                              </TableCell>
                              <TableCell className={classes.subheading}>
                                {moment(
                                  record.zReportDate?.slice(0, 10)
                                ).format('MM/DD/YYYY')}
                              </TableCell>
                              {console.log('link', record.zReportLink)}
                              <TableCell className={classes.subheading}>
                                <Link
                                  to={link}
                                  target="_blank"
                                  // className={classes.linkStyle}
                                >
                                  <div style={{ width: '30%' }}>
                                    <CustomButton
                                      className={classes.dateBtn}
                                      type="button">
                                      View
                                    </CustomButton>
                                  </div>
                                </Link>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      })}
                  </TableBody>
                )}
                {/* Table Body */}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Grid>
    </Grid>
  )
}
