import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  InputLabel,
  Select,
  AccordionDetails,
  TableHead,
  Accordion,
  AccordionSummary,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
// import google from 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2'
import React, {
  useState,
  Fragment,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../../../../assets/images/company_logo.jpg'
import CustomBreadcrumbs from '../../../../../common/CustomBreadcrumbs'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import CustomButton from '../../../../../common/CustomButton'
import { TASK_VIEW_PATH } from '../../../../../../helpers/constants/RoutePaths'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle'
import Avatar from 'react-avatar'
import SurveySuccess from './SurveySuccess'
import { uuid } from 'uuidv4'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import shortid from 'shortid'
import 'react-popper-tooltip/dist/styles.css'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SurveyInstruction from './innerComponents/SurveyInstruction'
import {
  GET_CATEGORY_LIST_URL,
  GET_CUSTOM_CATEGORY_URL,
  GET_CUSTOM_SUB_CATEGORY_URL,
  GET_PROJECT,
  GET_PROJECT_ITEM,
  GET_PROJECT_URL,
  GET_SAVED_PROJECT_USER_ITEM,
  GET_SCALE_RATING_BY_PROJECT,
  GET_SCALE_RATING_BY_SCALE_ID,
  GET_SUB_CATEGORY_LIST_URL,
  GET_TASK_PARTICIPANT_LIST,
  SAVED_OPEN_QUESTION_URL,
  SAVE_TASK_CONFIRMATION,
  UPDATE_PROJECT_USER_ITEM,
  ALL_SELECTED_LOAD_ITEMS,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import TaskOpenEndedQuestions from './TaskOpenEndedQuestions'
import { getCategory } from '../../../../../../redux/action'
import { Pagination } from '@mui/material'
import {
  authHeader,
  getOrganizationId,
  getQueryParamsByName,
  getUserId,
} from '../../../../../common/CustomFunction'
import {
  CUSTOM_ITEM_,
  SUB_CUSTOM_ITEM_,
  THINKWISE_CATEGORY,
  THINKWISE_SUB_CATEGORY,
} from '../../../../../../redux/action/Typeconstent'
import CustomLoader from '../../../../../common/CustomLoader'
import { CustomMessage, CustomPageLoading } from '../../../../../../function/HelperFunction'
import Swal from 'sweetalert2'
import TranslateComp from '../../../../../../helpers/constants/TranslateComp'
import { SelectAllOutlined } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  avatarStyle: {
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    color: 'rgb(255, 255, 255)',
    borderRadius: '50%',
    // background: 'rgb(255, 64, 128)',
    // background: theme.avasty[1],
    fontSize: '12px',
    display: 'inline-block',
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: '0px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  successBtn: {
    fill: theme.successColor,
  },
  descriptoion: {
    fontFamily: 'Roboto-Medium !important',
    fontSize: '16px !important',
    font: 'normal normal medium 16px/24px Roboto !important',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  pageheading: {
    color: '#000000DE !important',
    fontSize: '24px',
  },
  selected: {
    textAlign: ' left',
    color: ' #1565C0 !important',
    opacity: ' 1',
    fontFamily: 'Nunito-Regular !important',
  },
  unselected: {
    color: '#0000008A !important',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    minHeight: '0px !important',
  },
  expanded: {
    padding: '8px',
    margin: '0px !important',
  },
  paper: {
    width: '100%',
    padding: '10px',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'hidden',
    boxShadow: 'none',
    '@media (max-width: 599px)': {
      maxWidth: '483px',
    },
    '@media (max-width: 544px)': {
      maxWidth: '430px',
    },
    '@media (max-width: 480px)': {
      maxWidth: '360px',
    },
    '@media (max-width: 440px)': {
      maxWidth: '332px',
    },
    '@media (max-width: 400px)': {
      maxWidth: '290px',
    },
  },
  customTable: {
    // '& .MuiTableCell-sizeSmall': {
    //   padding: '20px',
    // },
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',
    height: '100%',
  },

  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    height: '100%',
    lineHeight: '1.5',

    // or borderTop: '1px solid red'
  },
  tableCellk: {
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    height: '100%',
    lineHeight: '1.5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
    },
  },
  tableRow: {
    height: 50,
  },
  roottitle: {
    fontFamily: 'Nunito-SemiBold !important',
    fontSize: '17px !important',
  },
  subheading: {
    fontFamily: 'Roboto-Regular !important',
  },
  subheadingk: {
    fontFamily: 'Roboto-Regular !important',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      fontSize: '15px',
    },
  },
  showndisplay: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  participantd: {
    display: 'initial',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'unset',
    },
  },
  survyAccordition: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
    },
  },
  subnamek: {
    marginTop: '1rem',
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0rem',
      marginLeft: '0rem',
    },
  },
  listcell: {
    justifyContent: 'center',
    display: 'flex',
    margin: 'auto',
    alignContent: 'center',

    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
      cursor: 'pointer',
    },
  },
  handlebg: {
    background: 'transparent !important',
    cursor: 'grab',
    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
      cursor: 'grab',
    },
  },
}))

export default function Newtaskdetail(props) {
  const classes = useStyles()
  const [isHover, setIsHover] = useState(false)
  const [participantsList, setParticipantsList] = useState([])
  const [scaleRatingList, setScaleRatingList] = useState([])
  const [taskOEQuestions, setTaskOEQuestions] = useState([])
  const [Allcategory, setAllcategory] = useState([])
  const [totalItems, settotalItems] = useState([])
  const [totalItemst1, settotalItemst1] = useState([])
  const [savedItemList, setSavedItemList] = useState({})
  const { id: projectId } = useParams()
  console.log('ids',projectId,props.match.params.id)
  const [particpenatId, setparticpenatId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [confirmCheck, setConfirmCheck] = useState(false)
  const [selectedItemId, setselectedItemId] = useState([])
  const taskId = getQueryParamsByName('taskId')
  const history = useHistory()
  const [projectDetails, setprojectDetails] = useState({})
  const [completedQuestions, setCompletedQuestions] = useState({});
const {enqueueSnackbar}=useSnackbar()
  // Function to check if all questions are completed
  const isPageComplete = () => {
    const currentItems = Allcategory[currentPage - 1]?.Items || [];
    return currentItems.every((sub) => completedQuestions[sub.itemId]);
  };
  
  // Function to update question completion status
  const markQuestionAsCompleted = (itemId, isCompleted) => {
    setCompletedQuestions((prevState) => ({
      ...prevState,
      [itemId]: isCompleted,
    }));
  };
  const handleNextPage = () => {
    if (!isPageComplete()) {
      CustomMessage('Please complete all questions on this page before proceeding.', 'error', enqueueSnackbar) //call toster

      // alert('Please complete all questions on this page before proceeding.');
      return;
    }
    setCurrentPage((prev) => prev + 1);
  };
const isPageValid = (page) => {
  const currentItems = Allcategory[page - 1]?.Items || [];
  
  // Ensure every item in currentItems has been answered in completedQuestions
  return currentItems.every((item) => Boolean(completedQuestions[item.itemId]));
};

  
  const handlePageChange = (event, newPage) => {
    // Ensure form validation or logic for the current page is applied before navigation
    // if (!isPageValid(currentPage)) {
    //   CustomMessage('Please complete all questions on this page before proceeding.', 'error', enqueueSnackbar) //call toster
    //   return;
    // }
  
    // Set the new page
    setCurrentPage(newPage);
  
    // Perform any additional logic for page change
    console.log('Navigating to page:', newPage);
  };
  
  console.log(
    Allcategory,
    taskOEQuestions,
    scaleRatingList,
    savedItemList,
    totalItemst1,
    particpenatId,
    currentPage,
    confirmCheck,
    selectedItemId,
    projectDetails,
    participantsList,
    'participantsListparticipantsListparticipantsList'
  )
  const colorCodesArr = [
    '#D32F2F',
    '#58A83A',
    '#ff4080',
    '#87eb87',
    '#ee82ee',
    '#bc8f8f',
    '#7171e4',
    '#d86e6e',
  ]
  async function getSavedProjectItems() {
    setloading(true)

    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SAVED_PROJECT_USER_ITEM,
      params: {
        ProjectID: props.match.params.id,
        RaterID: getUserId(),
      },
    }

    try {
      const response = await API(getConfig)
      if (response.status === 200) {
        const { ProjectUserItemList } = response.data
        console.log(ProjectUserItemList, 'projectitrems')
        let userId = {}
        let selectedId = {}
        let allitems = []
        let rd
        console.log('item', ProjectUserItemList)
        ProjectUserItemList?.forEach((val) => {
          allitems.push(val.zItemID)
          rd = allitems.filter(
            (item, index) => allitems.indexOf(item) === index
          )
          console.log('rd')
          if (val.zScaleRatingID != 0) {
            selectedId[val.zItemID] = [
              ...(selectedId[val.zItemID] || []),
              val.zUserID,
            ]
            userId[val.zItemID + '_' + val.zScaleRatingID] = [
              ...(userId[val.zItemID + '_' + val.zScaleRatingID] || []),
              val.zUserID,
            ]
          }
        })
        console.log(userId, selectedId, allitems, rd, 'listofitems')

        settotalItems(allitems)
        settotalItemst1(rd)
        setselectedItemId(selectedId)
        setSavedItemList(userId)
        console.log('userId', userId)
        console.log('Total item', rd.length)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  async function getScaleRating() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SCALE_RATING_BY_PROJECT,
      params: {
        ProjectID: projectId,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setScaleRatingList(data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }
  async function getallQuestions() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: ALL_SELECTED_LOAD_ITEMS,
      params: {
        ProjectID: projectId,
        UserID: getUserId(),
        OrganizationID: getOrganizationId(),
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        const [responseData] = data
        const {
          customSummary,
          thinkwiseCompetencyList,
          orgSummary,
        } = responseData
        console.log(thinkwiseCompetencyList, customSummary, 'customSummary')
        const [customitem] = customSummary
        const [thinkwiseCompetency] = thinkwiseCompetencyList
        const { competencyList } = thinkwiseCompetency
        const { loadCustomSummary } = customitem
        const [originSummaryItem] = orgSummary
        const { loadOrgSummary } = originSummaryItem
        console.log(loadOrgSummary, 'originSummaryItem')
        let itemData = {}
        let newCOmpenteId = []
        let questionnumber = 0
        competencyList.forEach((co) => {
          let isItem = []
          let competencyNamesplit = co.zNameComp.split('##')
          let subcategoryList = co.competencyListData.map((val) => {
            let spliCompname = val.zNameCap.split('##')
            let Items = val.zCaption.map((val) => {
              let spliItemname = val.split('##')
              questionnumber++
              return {
                itemId: spliItemname[0],
                itemName: spliItemname[1],
                questionnumber,
              }
            })
            isItem.push(...Items)
            return {
              subcategoryId: spliCompname[0],
              subcategoryName: spliCompname[1],
              Items,
            }
          })
          if (isItem.length) {
            newCOmpenteId.push(...subcategoryList)
          }
          return {
            categoryId: competencyNamesplit[0],
            categoryName: competencyNamesplit[1],
            subcategoryList,
          }
        })
        console.log(loadOrgSummary, 'originSummaryItem1')

        // setAllcategory(newCOmpenteId)
        let selectedCustom = []
        let items = {}
        loadCustomSummary?.forEach((val) => {
          let categoryNamesplit = val.zNameComp.split('##')
          let ALLCATEGORYNAME = CUSTOM_ITEM_ + categoryNamesplit[0]
          items[ALLCATEGORYNAME] = { ...items[ALLCATEGORYNAME] }
          if (val.zCaptionComp.length) {
            let itemList = []
            let Items = val.zCaptionComp.map((value) => {
              let itemNAmesplit = value.split('##')
              questionnumber++
              return {
                itemId: itemNAmesplit[0],
                itemName: itemNAmesplit[1],
                questionnumber,
              }
            })
            newCOmpenteId.push({
              subcategoryId: categoryNamesplit[0],
              subcategoryName: categoryNamesplit[1],
              Items,
            })
          }
          if (val.competencyListData.length) {
            let allValues = val.competencyListData.map((com) => {
              let compnamesplit = com.zNameCap.split('##')

              let Items = com.zCaption.map((it) => {
                let itemnamesplit = it.split('##')
                questionnumber++
                return {
                  itemId: itemnamesplit[0],
                  itemName: itemnamesplit[1],
                  questionnumber,
                }
              })
              console.log('items', Items)
              return {
                subcategoryId: compnamesplit[0],
                subcategoryName: compnamesplit[1],
                Items,
              }
            })
            newCOmpenteId.push(...allValues)
          }
          // selectedsCustom.push(categoryNamesplit[0])
        })

        const listOfSummary = []
        const isItem12 = []
        let listNumber = 0
        console.log(loadOrgSummary, 'originSummaryItem2')

        loadOrgSummary?.forEach((element) => {
          console.log(isItem12, 'Items12Items12',element)
          const array = []
          let SubId = ''
          const competencyNamesplit = element.zNameComp.split('##')
          console.log(competencyNamesplit, 'item cnp')

          let Items12 
          if(element.zCaptionComp&&element.zCaptionComp.length>0){
            console.log('if call')
          Items12= element.zCaptionComp?.map((val) => {
            const spliItemname = val?.split('##')
            console.log(spliItemname, 'spliItemname', competencyNamesplit[0])
            listNumber++
            SubId = competencyNamesplit[0]
            if (competencyNamesplit[0] == SubId) {
              let subListCatocary = {
                itemId: spliItemname[0],
                itemName: spliItemname[1],
                questionnumber: listNumber,
              }
              array.push(subListCatocary)
            }
          })}
          else{
            console.log('else call')

           Items12 = element.competencyListData?.map((val) => {
            let spliCompname = val.zNameCap.split('##')
            let Items = val.zCaption.map((val) => {
              let spliItemname = val.split('##')
              console.log('val give',val,spliItemname)
               listNumber++
                let subListCatocary = {
                 itemId: spliItemname[0],
                itemName: spliItemname[1],
                questionnumber:listNumber,
              }
             
            
             
               array.push(subListCatocary)
            })
          })
          }
          console.log('array',array)
          listOfSummary.push({
            Items: array,
            subcategoryId: competencyNamesplit[0],
            subcategoryName: competencyNamesplit[1],
          })
          isItem12.push(Items12)
          if (isItem12.length) {
            listOfSummary?.push(...Items12)
          }
        })
        const summaryListData = []
        const datalist = listOfSummary.map((item) => {
          if (item === undefined) {
            return null
          } else {
            summaryListData.push(item)
          }
        })
console.log(listOfSummary,newCOmpenteId,'news')
if (listOfSummary.length > 0) {
  setAllcategory([...summaryListData, ...newCOmpenteId]);
} else {
  setAllcategory([...newCOmpenteId]);
}

      }
    } catch (e) {
      console.log(e)
    }
  }
  console.log('allcateg',Allcategory)
  async function getTaskQuestionList() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: SAVED_OPEN_QUESTION_URL,
      params: {
        ProjectID: props.match.params.id,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setTaskOEQuestions(data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getParticipantList() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TASK_PARTICIPANT_LIST,
      params: {
        ProjectID: projectId,
        RespondentID: getUserId(),
        // ParticipantId: '',
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (data) {
        let participantList = data.participantlist
        participantList.map((participant) => (participant.id = uuid()))
        let incrementVar = 0
        participantList.map((participant, index) => {
          if (index == colorCodesArr.length) {
            incrementVar = 0
          }
          participant.bgcolor = colorCodesArr[incrementVar]
          incrementVar++
          return participant
        })
        setParticipantsList(participantList)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getSavedProjectItems()
    getTaskQuestionList()
    getParticipantList()
    getScaleRating()
    getallQuestions()
  }, [])
  const AvatherRender = (props) => {
    console.log('props', props.bgcolor)
    const classes = useStyles()

    const capLetFunc = () => {
      const words = props.name.zParticipantFullName.split(' ')
      console.log('props words', words)
      const newData = words
        .map((word) => {
          if (word[0]) {
            return word[0].toUpperCase()
          }
        })
        .join('')
      console.log('props words final', newData)

      return newData
    }
    return (
      // <Avatar name={props.name.zParticipantFullName} size="30" round={true} />
      <div
        className={classes.avatarStyle}
        style={{ backgroundColor: props.bgcolor }}>
        {capLetFunc()}
      </div>
    )
  }
  const capLetFunc = (name) => {
    const words = name.zParticipantFullName.split(' ')
    console.log('props words', words)
    const newData = words
      .map((word) => {
        if (word[0]) {
          return word[0].toUpperCase()
        }
      })
      .join('')
    console.log('props words final', newData)

    return newData
  }
  const capLetFun = (name) => {
    const words = name.split(' ')
    console.log('props words', words)
    // const finalWord =
    const newData = words
      .map((word) => {
        if (word[0]) {
          return word[0].toUpperCase()
        }
      })
      .join('')
    console.log('props words final', newData)

    return newData
  }
  const [isOpenSurveyTour, setSurveyTour] = useState(false)

  const Content = styled.div`
    margin-right: 2px;
  `
  const Itemlisting = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: #fff;
    ${(props) => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};
  `
  const Item = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: transparent;
    ${(props) => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};
  `
  // width: 100%;
  // display: flex;
  const Clone = styled(Item)`
    + div {
      display: none !important;
    }
  `

  const Handle = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    user-select: none;
    line-height: 1.5;
    border-radius: 3px 0 0 3px;
    background: #fff;
    color: #000;
  `
  // border: 1px

  const List = styled.div`
    ${(props) => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
    background: #fff;
    padding: 0.5rem 0.5rem 0;
    flex: 0 0 150px;
    font-family: sans-serif;
  `
  const ListAvatar = styled.div`
    ${(props) => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
    background: transparent;
    padding: 0.5rem 0.5rem 0;
    flex-wrap: wrap;
    font-family: sans-serif;
    width: 100%;
    width: 100px;
    display: flex;
  `
  const Kiosk = styled(List)`
    position: absolute;
    width: 200px;
  `

  const [submitFlag, setFlag] = useState(false)
  const submitFlagFun = () => {
    setFlag(!submitFlag)
  }

  const handleSurveyInstruction = () => {
    setSurveyTour(!isOpenSurveyTour)
  }
  const [loading, setloading] = useState(false)
  const handleSubmitTask = async () => {
    let incomplteSurvey = totalComplete == totalItems.length
    console.log(totalComplete - totalItems.length)
    let surveyText = incomplteSurvey
      ? 'You completed all of the survey items for each participant. Please click below the submit'
      : `You have ${totalItems.length -
          totalComplete} incomplete item are you sure want to submit?`
    Swal.fire({
      title: incomplteSurvey ? 'Congratulations!' : 'Incomplete!',
      text: surveyText,
      // icon: 'warning',
      // showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText:
        '<div style="height: 38px;align-items: center;display: flex;" ><h1>Submit Survey</h1></div>',
      cancelButtonColor: '#d33',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          method: 'POST',
          headers: authHeader(),

          url: SAVE_TASK_CONFIRMATION,
          data: {
            taskUserID: taskId,
            projectID: props.match.params.id,
            userID: getUserId(),
            sessionID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          },
        }
        const response = await API(config)

        if (response.status === 200) {
          Swal.fire({
            title:
              "<h5 style='color:#fff'>Your Survey submitted successfully</h5>",
            background: '#00cc00',
          }).then((value) => {})
          history.push('/tasks')
        }
      }
    })
  }

  const handleSaveItem = async (participantId, itemId, scaleRatingId) => {
    setloading(true)
    console.log(savedItemList, Number(itemId), Number(scaleRatingId))
    const updateConfig = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_PROJECT_USER_ITEM,
      data: {
        projectID: projectId,
        userID: participantId,
        raterID: getUserId(),
        itemID: Number(itemId),
        scaleRatingID: Number(scaleRatingId),
      },
    }
    try {
      await API(updateConfig)
      // let newsavedItemList = { ...savedItemList }
      // newsavedItemList[Number(itemId) + '_' + Number(scaleRatingId)] = [
      //   ...(newsavedItemList[Number(itemId) + '_' + Number(scaleRatingId)] ||
      //     []),
      //   participantId,
      // ]

      getSavedProjectItems()
    } catch (ex) {
      console.error(ex)
    } finally {
      setloading(false)
    }
  }

  const getParticipate = useCallback((value) => {
    const { parid } = value.currentTarget.dataset
    setparticpenatId(parid)
    // setslectMultipledata((val) => [value])
  }, [])

  const getTask = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT,
      params: {
        ProjectID: projectId,
      },
    }
    try {
      const response = await API(config)
      const { data } = response.data
      setprojectDetails(data[0])
    } catch (er) {
      console.error(er)
    }
  }

  const SurveyTable = useCallback((value) => {
    const { zName, zDescription } = value
    return (
      <>
        {' '}
        <p style={{ margin: '0px 0px' }}>{zName}</p>
        <p style={{ margin: '0px 0px' }}>{zDescription || ''}</p>
      </>
    )
  }, [])

  useEffect(() => {
    getTask()
  }, [])
  console.log('task detail', participantsList)
  let contenttask = useRef()

  const onDragEnd = (result) => {
    const { destination, draggableId, source } = result
    if (!destination) {
      return null
    }
    const { droppableId: reachID } = destination
    let reachIDSplit = reachID.split('_')
    const { droppableId: homeId } = source
    let homeIDSplit = homeId.split('_')

    if (homeId == 'participantList') {
      const splitId = reachID.split('_')
      let itemId = splitId[0]
      let scaleId = splitId[1]
      // console.log("i'm calling")
      handleSaveItem(draggableId, itemId, scaleId)
    } else if (reachIDSplit[0] == homeIDSplit[0]) {
      const splitId = reachID.split('_')
      let itemId = splitId[0]
      let scaleId = splitId[1]
      let userIdsplit = draggableId.split('_')
      let pid = userIdsplit[1]
      // console.log("i'm calling to", itemId, scaleId, userIdsplit[1])
      let newsavedItemList = { ...savedItemList }
      newsavedItemList[Number(itemId) + '_' + Number(scaleId)] = [
        ...(newsavedItemList[Number(itemId) + '_' + Number(scaleId)] || []),
        userIdsplit[1],
      ]
      console.log('saved new', newsavedItemList)
      setSavedItemList('')
      setSavedItemList(newsavedItemList)
      handleSaveItem(pid, itemId, scaleId)
    }
  }
  console.log('seved', savedItemList)
  const callBack = (pid, itemId, scaleId) => {
    console.log('call back called')
    // handleSaveItem(pid, itemId, scaleId)
  }
  let openend = useRef()
  const clickFordrag = (itemid, scaleid) => {
    console.log(particpenatId)
    if (!particpenatId) {
      return null
    }
    handleSaveItem(particpenatId, itemid, scaleid)
  }
  let totalComplete = Object.values(selectedItemId).filter(
    (va) => va.length == participantsList.length
  ).length
  console.log('complete', totalComplete, Object.values(savedItemList).length)
  let overAllref = useRef()

  useEffect(() => {
    overAllref?.current?.scrollIntoView({
      top: 0,

      behavior: 'smooth',
    })

    contenttask?.current?.scrollIntoView({
      top: 0,

      behavior: 'smooth',
    })
    // openend?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [currentPage])
  const googleTranslateElementInit1 = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: true,
      },
      'google_translate_element2'
    )
  }
  useEffect(() => {
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit1'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit1 = googleTranslateElementInit1
  }, [])

  let Listparticpartcpant = React.useCallback(
    (props) => {
      return (
        <Droppable droppableId="participantList" isDropDisabled={true}>
          {(provided, snapshot) => (
            <Kiosk
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}>
              {props.participantsList.map((item, index) => {
                console.log(props, item.zParticipantID)
                return (
                  <div
                    onClick={props.getParticipate}
                    data-parid={item.zParticipantID}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor:
                        //         slectMultipledata.find(
                        //     (val) => val.draggableId == String(item.id)
                        //   )
                        props.particpenatId == item.zParticipantID
                          ? '#ECEDEF'
                          : 'none',
                    }}>
                    <Draggable
                      key={String(item.id)}
                      draggableId={'' + item.zParticipantID}
                      index={index}>
                      {(provided, snapshot) => (
                        <Fragment>
                          <Item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}>
                            {/* {item.content} */}
                            {/* <Avatar
                              name={item.zParticipantFullName}
                              size="30"
                              round={true}
                            /> */}
                            <div
                              className={classes.avatarStyle}
                              style={{ backgroundColor: item.bgcolor }}>
                              {capLetFun(item.zParticipantFullName)}
                            </div>
                            {/* {item.content} */}
                          </Item>
                          {snapshot.isDragging && (
                            // <Clone>{item.content}</Clone>
                            <Item>
                              <div
                                className={classes.avatarStyle}
                                style={{ backgroundColor: item.bgcolor }}>
                                {capLetFun(item.zParticipantFullName)}
                              </div>
                            </Item>
                          )}
                          <div {...provided.dragHandleProps}>
                            {item.zParticipantFullName}
                          </div>
                        </Fragment>
                      )}
                    </Draggable>
                  </div>
                )
              })}
            </Kiosk>
          )}
        </Droppable>
      )
    },
    [particpenatId]
  )

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: true,
      },
      'google_translate_element2'
    )
  }
  // const AvatherRender = React.memo((props) => {
  //   return (
  //     <Avatar name={props.name.zParticipantFullName} size="30" round={true} />
  //   )
  // })

  useEffect(() => {
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
    // window.addEventListener('beforeunload', closeTab);
    return () => {
      // window.removeEventListener('beforeunload', closeTab);
    }
  }, [])

  //console.log("category length",category.length, "currentpage",currentPage , (currentPage === (category.length + 1)) ,(completedItems.length === items.length) )
  return (
    <>
      <div>{/* <TranslateComp /> */}</div>
      <div
        ref={(e) => {
          overAllref.current = e
        }}>
        {/* {loading && <CustomPageLoading />} */}
        {/* <TranslateComp /> */}

        <Grid container spacing={1}>
          {/* <Grid item xs={6} lg={6} md={6}>
            <Typography
              className={classes.pageheading}
              variant="h6"
              gutterBottom>
              Tasks - ThinkWise 360
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.selected} color="textPrimary">
                Home
              </Typography>
              <Typography className={classes.selected} color="textPrimary">
                Tasks
              </Typography>
              <Typography className={classes.unselected} color="textPrimary">
                {projectDetails?.zName}
              </Typography>
            </Breadcrumbs>
          </Grid> */}
          {/* <Grid item xs={6} lg={6} md={6} style={{ textAlign: 'right' }}> */}
          {/* <div className={classes.sideHead}>
              <img src={logo} alt="logo" width="100" height="60" />
            </div> */}
          {/* <div id="google_translate_element"></div> */}
          {/* </Grid> */}
          {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <Typography variant="h6" gutterBottom>
              {projectDetails?.zName}
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">
                {projectDetails?.zDescription}
              </Typography>
            </Breadcrumbs>
          </Grid>
          {/* <Grid item xs={2}> */}

          {/* </Grid> */}

          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            style={{
              textAlign: { xs: 'left', md: 'right' },
              marginTop: '10px',
            }}>
            <TranslateComp />
          </Grid>
        </Grid>
        <div>{/* <TranslateComp /> */}</div>
        {/* <TranslateComp /> */}
        <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
          <div>
            <Paper className={classes.paper}>
              {/* Table tools */}
              <Toolbar className={classes.root}>
                <Typography
                  className={classes.roottitle}
                  variant="h6"
                  id="tableTitle"
                  component="div">
                  Please rate each Participant for all of the items listed
                  below.
                </Typography>
                <div>
                  <p
                    style={{
                      cursor: 'pointer',
                      textAlign: 'right',
                    }}
                    onClick={() => handleSurveyInstruction()}>
                    Survey Instructions
                  </p>
                  <div id="google_translate_element"></div>
                </div>
              </Toolbar>
              <Divider />
              <DragDropContext onDragEnd={onDragEnd}>
                <Grid
                  container
                  spacing={3}
                  style={{ width: '100%', margin: 0 }}>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    className={classes.showndisplay}></Grid>
                  <Grid item lg={6} md={6} xs={6}>
                    <Typography
                      className={classes.subheading}
                      variant="h6"
                      gutterBottom>
                      Items
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    xs={6}
                    style={{ textAlign: 'right' }}>
                    <Typography color="textPrimary">
                      Completed Item {totalComplete} of {totalItemst1.length}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    xs={0}
                    style={{ padding: '0px 12px' }}></Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    xs={12}
                    style={{ padding: '0px 12px' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Typography
                        className={classes.subheading}
                        color="textPrimary">
                        Rate each participant for each item. An item is complete
                        when all participants are rated and the green check mark
                        appears
                      </Typography>
                    </Breadcrumbs>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={3}
                    className={classes.participantd}>
                    <Grid item xs={12} sm={3} md={12} lg={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.subheadingk}>
                        Participants
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      // sm={3}
                      md={12}
                      lg={12}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Typography
                          color="textPrimary"
                          className={classes.subheadingk}>
                          Select a participant from the list
                        </Typography>
                      </Breadcrumbs>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      // sm={6}
                      md={12}
                      lg={12}
                      disabled={true}
                      className={classes.subnamek}
                      style={{}}>
                      <div>
                        <Listparticpartcpant
                          getParticipate={getParticipate}
                          participantsList={participantsList}
                          particpenatId={particpenatId}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    md={9}
                    style={{
                      overflow: 'auto',
                      width: '100%',
                    }}
                    className={classes.survyAccordition}>
                    {!!Allcategory.length && Allcategory[currentPage - 1] && (
                      <Accordion
                        ref={(e) => {
                          contenttask.current = e
                        }}
                        expanded={true}
                        className={classes.acc}>
                        <AccordionSummary
                          aria-controls={`item_-content`}
                          id={`item_-header`}
                          classes={{
                            expanded: classes.expanded,
                          }}
                          className={classes.accHead}>
                          <Grid item xs={10}>
                            <Typography className={classes.heading}>
                              {Allcategory[currentPage - 1]?.subcategoryName}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accDetail}>
                          <Grid
                            container
                            spacing={3}
                            direction="row"
                            justifyContent="center">
                            {/* <Grid item xs={12}>
                            <p style={{ margin: '0px 0px' }}> */}
                            {/* {category[currentPage - 1].zDescription} */}
                            {/* </p>
                          </Grid> */}
                          {console.log('All category',Allcategory,Allcategory[currentPage-1])}
                            {Allcategory[currentPage - 1]?.Items.map(
                              (sub, index) => {
                                console.log('all', sub.length)

                                return (
                                  <>
                                    {/* <Grid
                                    item
                                    xs={12}
                                    style={{
                                      backgroundColor: '#F6F6F6',
                                      margin: '0px 0px',
                                    }}>
                                    <b>
                                      {' '}
                                      <p
                                        style={{
                                          fontFamily:
                                            'Roboto-Regular !important',
                                          fontSize: '16px',
                                          margin: '0px 0px',
                                        }}>
                                        {sub.subcategoryName}
                                      </p>
                                    </b>
                                  </Grid> */}
                                    {/* <Grid item xs={12}>
                                    <p
                                      style={{
                                        fontFamily: 'Roboto-Regular !important',
                                        fontSize: '14px',
                                        margin: '0px 0px',
                                      }}>
                                      {/* {sub.zDescription} 
                                    </p>
                                  </Grid> */}

                                    <Grid item xs={12}>
                                      {/* {sub.Items.map((item, index) => {
                                      return (
                                      */}
                                      <>
                                        {' '}
                                        <p
                                          className={classes.descriptoion}
                                          style={{
                                            display: 'flex',
                                            fontWeight: 700,
                                            margin: '0px 0px',
                                          }}>
                                          {console.log(
                                            'parti',
                                            participantsList.length
                                          )}
                              
                                          <CheckCircleOutlineIcon
                                            className={classes.successBtn}
                                            style={{
                                              visibility: `${
                                                // [].includes(sub.zItemID)
                                                (participantsList?.length > 0 &&
                                                  (
                                                    selectedItemId[
                                                      sub.itemId
                                                    ] || []
                                                  ).length) ==
                                                participantsList?.length
                                                  ? 'visible'
                                                  : 'hidden'
                                              }`
                                            }}
                                          />
                                          {sub.questionnumber}. {sub.itemName}
                                        </p>
                                        <TableContainer
                                          component={Paper}
                                          className={classes.tablecontainer}
                                          style={{
                                            borderRadius: 'unset',

                                            'overflow-x': 'auto',
                                          }}>
                                          <Table
                                            stickyHeader
                                            size="small"
                                            className={classes.customTable}
                                            style={{
                                              borderColor: '#C3C3C3',
                                              borderCollapse: 'collapse',
                                              marginBottom: '1rem',
                                            }}>
                                            <TableHead>
                                              <TableRow>
                                                {scaleRatingList?.map(
                                                  (scaleRating) => {
                                                    return (
                                                      <TableCell
                                                        className={
                                                          classes.tableCell
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            '#D0E9FC',
                                                          borderColor:
                                                            '#C3C3C3',
                                                          // padding:
                                                          //   '6px 6px 6px 6px',
                                                          height: '1rem',
                                                          textAlign: 'center',
                                                          width: '300px',
                                                        }}>
                                                        <Grid>
                                                          {SurveyTable(
                                                            scaleRating
                                                          )}
                                                        </Grid>
                                                      </TableCell>
                                                    )
                                                  }
                                                )}
                                              </TableRow>
                                            </TableHead>
                                            {/* Table Header */}

                                            {/* Table Body */}
                                            <TableBody>
                                              <TableRow
                                                className={classes.tableRow}>
                                                {scaleRatingList.map(
                                                  (scale) => {
                                                    let userId =
                                                      savedItemList[
                                                        sub.itemId +
                                                          '_' +
                                                          scale.zScaleRatingID
                                                      ] || []
                                                    return (
                                                      <TableCell
                                                        align="left"
                                                        className={
                                                          classes.tableCellk
                                                        }
                                                        style={{
                                                          borderColor:
                                                            '#C3C3C3',
                                                          height: '100px',
                                                          padding: 'unset',
                                                        }}>
                                                        <Content
                                                          style={{
                                                            height: '100%',
                                                            display: 'flex',
                                                          }}
                                                          id="table-content">
                                                          <Droppable
                                                            key={sub.itemId}
                                                            droppableId={String(
                                                              sub.itemId +
                                                                '_' +
                                                                scale.zScaleRatingID
                                                            )}>
                                                            {(
                                                              provided,
                                                              snapshot
                                                            ) => (
                                                              <ListAvatar
                                                                className={
                                                                  classes.listcell
                                                                }
                                                                style={{
                                                                  height:
                                                                    '100%',
                                                                  width:
                                                                    '125px',
                                                                  // background: 'red',
                                                                }}
                                                                onClick={() =>
                                                                  clickFordrag(
                                                                    sub.itemId,
                                                                    scale.zScaleRatingID
                                                                  )
                                                                }
                                                                ref={
                                                                  provided.innerRef
                                                                }
                                                                isDraggingOver={
                                                                  snapshot.isDraggingOver
                                                                }>
                                                                {userId.map(
                                                                  (
                                                                    userData
                                                                  ) => {
                                                                    let name =
                                                                      participantsList.find(
                                                                        (val) =>
                                                                          val.zParticipantID ==
                                                                          userData
                                                                      ) || {}

                                                                    let bcg =
                                                                      (participantsList &&
                                                                        participantsList.find(
                                                                          (
                                                                            val
                                                                          ) =>
                                                                            val?.zParticipantID ==
                                                                            userData
                                                                        )
                                                                          ?.bgcolor) ||
                                                                      {}

                                                                    return (
                                                                      <Draggable
                                                                        key={
                                                                          sub.itemId
                                                                        }
                                                                        draggableId={
                                                                          sub.itemId +
                                                                          '_' +
                                                                          userData
                                                                        }
                                                                        index={
                                                                          sub.itemId
                                                                        }>
                                                                        {(
                                                                          provided,
                                                                          snapshot
                                                                        ) => (
                                                                          <Item
                                                                            ref={
                                                                              provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            isDragging={
                                                                              snapshot.isDragging
                                                                            }
                                                                            style={{
                                                                              ...provided
                                                                                .draggableProps
                                                                                .style,
                                                                            }}
                                                                            id={`tooltip_${sub.id}`}>
                                                                            <Handle
                                                                              className={
                                                                                classes.handlebg
                                                                              }
                                                                              {...provided.dragHandleProps}>
                                                                              {/* {item.content} */}
                                                                              {/* <AvatherRender
                                                                            name={
                                                                              name
                                                                            }
                                                                            bgcolor={
                                                                              bcg
                                                                            }
                                                                          /> */}
                                                                              {/* {!loading ? ( */}
                                                                              <div
                                                                                className={
                                                                                  classes.avatarStyle
                                                                                }
                                                                                style={{
                                                                                  backgroundColor: bcg,
                                                                                }}>
                                                                                {capLetFunc(
                                                                                  name
                                                                                )}
                                                                              </div>
                                                                              {/* ) : ( */}
                                                                              {/* '' */}
                                                                              {/* )} */}
                                                                            </Handle>
                                                                          </Item>
                                                                          // </Tooltip>
                                                                        )}
                                                                      </Draggable>
                                                                    )
                                                                  }
                                                                )}

                                                                {
                                                                  provided.placeholder
                                                                }
                                                              </ListAvatar>
                                                            )}
                                                          </Droppable>
                                                        </Content>
                                                      </TableCell>
                                                    )
                                                  }
                                                )}
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </>
                                    </Grid>
                                  </>
                                )
                              }
                            )}

                            <Grid item xs={1}></Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {Allcategory.length + 1 === currentPage ? (
                      <div style={{ paddingTop: '40px' }}>
                        <TaskOpenEndedQuestions
                          ref={openend}
                          participantList={participantsList}
                          taskOEQuestions={taskOEQuestions}
                          projectId={props.match.params.id}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  {submitFlag && (
                    <SurveySuccess
                      open={submitFlag}
                      handleReset={submitFlagFun}
                    />
                  )}
                  {isOpenSurveyTour && (
                    <SurveyInstruction
                      open={isOpenSurveyTour}
                      handleReset={handleSurveyInstruction}
                    />
                  )}
                </Grid>
              </DragDropContext>
              <Grid container>
                <Grid lg={3} md={3} className={classes.showndisplay}></Grid>
                <Grid xs={12} lg={9} md={9}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '20px',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Pagination
                        page={currentPage}
                        count={Allcategory.length+1}
                        color="primary"
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} md={3} lg={3}></Grid>
                <Grid xs={12} md={9} lg={9}>
                  {currentPage === Allcategory.length + 1 && (
                    <div>
                      <p>
                        By checking the box below and clicking SUBMIT, I
                        understand my ratings are final and cannot be changed.
                      </p>
                      <p>
                        {console.log('total',totalComplete,totalItemst1.length)}
                        <FormControlLabel
  control={
    <Checkbox
      color="primary"
      name="all"
      checked={confirmCheck} // Controlled state
      onChange={(e) => {
        if (e.target.checked) {
          if (totalComplete !== totalItemst1.length || totalComplete === 0) {
            // Show error if conditions aren't met
            CustomMessage(
              'Please complete all ratings before finalizing.',
              'error',
              enqueueSnackbar
            );
            return; // Prevent state change
          } else {
            setConfirmCheck(true); // Set to true only if conditions are met
          }
        } else {
          setConfirmCheck(false); // Allow unchecking
        }
      }}
    />
  }
  label="Yes, all ratings are final."
/>


                      </p>
                      <CustomButton
                        colour="primaryButton"
                        disabled={!confirmCheck}
                        onClick={handleSubmitTask}>
                        Submit
                      </CustomButton>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </div>
    </>
  )
}
